import UnhandledExceptions from 'app/UnhandledExceptions'

import ErrorBoundary from 'core/components/lib/ErrorBoundary/ErrorBoundary'

import BootstrapManager from './BootstrapManager'
import ToastProvider from './ToastProvider'

export default function Root() {
  return (
    <ErrorBoundary ident='AppRoot' isAppRoot>
      <ToastProvider>
        <UnhandledExceptions>
          <BootstrapManager />
        </UnhandledExceptions>
      </ToastProvider>
    </ErrorBoundary>
  )
}
